import {
  BRANDING_TYPE__ZEDOC,
  BRANDING_TYPE__ZESIGHT,
} from '../common/constants/branding';
import settings from '../common/settings';

export const getBranding = () =>
  settings.public.branding || BRANDING_TYPE__ZEDOC;

export const getProductName = () => {
  return process.env.REACT_APP_TITLE;
};

export const getMatomoOptions = () => {
  switch (getBranding()) {
    case BRANDING_TYPE__ZESIGHT:
      return {
        url: 'https://mtm.zedoc.io/',
        siteId: 5,
      };
    default:
      return null;
  }
};
