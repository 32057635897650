import forEach from 'lodash/forEach';

// by default we don't merge, instead we simply replace with new task.
const defaultMerge = (currentTask, task) => task;

class DelayedTask {
  constructor({ action, merge = defaultMerge, delayMs = 1000 } = {}) {
    Object.assign(this, {
      merge,
      action,
      delayMs,
    });
    this.scheduled = {};
    this.pending = {};
    this.queued = {}; // queued => pending exists
  }

  finalize(id, errorHandler) {
    return (valueOrError) => {
      if (this.pending[id]) {
        delete this.pending[id];
      }
      if (this.queued[id]) {
        return this.perform(id, this.queued[id]);
      }
      if (errorHandler) {
        return Promise.reject(valueOrError);
      }
      return Promise.resolve(valueOrError);
    };
  }

  perform(id, params) {
    if (this.queued[id]) {
      delete this.queued[id];
    }
    if (this.scheduled[id]) {
      if (this.scheduled[id].timeout) {
        clearTimeout(this.scheduled[id].timeout);
      }
      delete this.scheduled[id];
    }
    const promise = this.action(id, params);
    this.pending[id] = {
      params,
      promise,
    };
    return this.pending[id].promise.then(
      this.finalize(id),
      this.finalize(id, true),
    );
  }

  schedule(id, params) {
    if (this.pending[id]) {
      // queued => pending exists, so it's enough to check pending
      this.queued[id] = this.merge(this.queued[id], params);
      return;
    }

    if (this.scheduled[id] && this.scheduled[id].timeout) {
      clearTimeout(this.scheduled[id].timeout);
    }

    const task = {
      params: this.merge(
        this.scheduled[id] && this.scheduled[id].params,
        params,
      ),
      timeout: setTimeout(() => {
        delete task.timeout;
        if (this.pending[id]) {
          this.schedule(id, task.params); // set queued in fact
        } else {
          this.perform(id, task.params);
        }
      }, this.delayMs),
    };

    this.scheduled[id] = task;
  }

  cancelAll() {
    this.queued = {};
    this.pending = {};
    forEach(this.scheduled, ({ timeout }) => {
      if (timeout) {
        clearTimeout(timeout);
      }
    });
    this.scheduled = {};
  }

  runNow(id, params) {
    if (params) {
      return this.perform(id, params);
    }
    if (this.scheduled[id]) {
      return this.perform(id, this.scheduled[id].params);
    }
    if (this.pending[id]) {
      return this.waitFor(id);
    }
    // NOTE: This one will also handle queued if any.
    return this.finalize(id)();
  }

  waitFor(id) {
    if (this.pending[id]) {
      return this.pending[id].promise;
    }
    return Promise.resolve();
  }

  isPending(id) {
    return !!this.pending[id];
  }
}

export default DelayedTask;
