import memoize from 'lodash/memoize';
import forEach from 'lodash/forEach';
import { createSelector } from 'reselect';
import EvaluationScope from '../../models/EvaluationScope';
import QuestionCursor from '../../models/QuestionCursor';
import {
  maskHiddenFormValues,
  getFormErrors,
  getDynamicQuestionnaire,
  evaluateFormValuesAndProperties,
} from '../../utils/questionnaire';
import cleanEmptyValues from '../../utils/cleanEmptyValues';
import {
  constant,
  argument,
  reconcilingSelector,
  higherOrderSelector,
} from '../../utilsClient/selectors';
import createGetAtKey from '../../utilsClient/createGetAtKey';
import toSelector from '../../utils/toSelector';
import { mergeFormValues } from '../../utils/formValues';

export const liftContextSelectors = (selectContext) => {
  const select = {};
  forEach(
    [
      'questionnaire',
      'validationErrors',
      'formValues',
      'dynamicProperties',
      'hasValidationErrors',
      'formErrors',
      'touched',
      'evaluationScope',
      'dynamicQuestionnaire',
      'questionCursor',
      'context',
      'name',
      'options',
      'meta',
    ],
    (name) => {
      select[name] = (...args) =>
        higherOrderSelector(selectContext, (context) =>
          context.select[name](...args),
        );
    },
  );
  return select;
};

export const createDynamicQuestionnaireSelectors = ({
  selectRawFormValues,
  selectRawTouched,
  selectVariables,
  selectValidationErrors,
  selectPropertiesOverrides,
  selectQuestionnaire,
  selectSortedBy,
  selectFlatSections,
}) => {
  const selectEmptyEvaluationScope = createSelector(
    toSelector(selectVariables),
    toSelector(selectQuestionnaire),
    (variables, questionnaire) =>
      new EvaluationScope({
        questionnaire,
        variables,
        answers: {},
      }),
  );

  const selectInitialValues = memoize(() =>
    createSelector(selectEmptyEvaluationScope, (evaluationScope) =>
      evaluationScope.getInitialValues(),
    ),
  );

  const selectRawFormValuesAndInitialValues = reconcilingSelector(
    toSelector(selectRawFormValues),
    selectInitialValues(),
    (rawFormValues, initialValues) =>
      mergeFormValues(initialValues, rawFormValues),
  );

  const selectStaticEvaluationScope = createSelector(
    selectEmptyEvaluationScope,
    selectRawFormValuesAndInitialValues,
    (emptyEvaluationScope, formValues) =>
      emptyEvaluationScope.copyWithFormValues(formValues),
  );

  const selectFormValuesAndDynamicProperties = reconcilingSelector(
    selectStaticEvaluationScope,
    toSelector(selectPropertiesOverrides),
    (evaluationScope, propertiesOverrides) =>
      evaluateFormValuesAndProperties(evaluationScope, {
        propertiesOverrides,
      }),
  );

  const selectFormValues = memoize(() =>
    createSelector(
      selectFormValuesAndDynamicProperties,
      argument(0, 'formValues'),
    ),
  );

  const selectDynamicProperties = memoize(() =>
    createSelector(
      selectFormValuesAndDynamicProperties,
      argument(0, 'properties'),
    ),
  );

  const selectRootEvaluationScope = memoize(() =>
    createSelector(
      selectStaticEvaluationScope,
      selectFormValues(),
      (staticEvaluationScope, formValues) =>
        staticEvaluationScope.copyWithFormValues(formValues),
    ),
  );

  const dynamicVariables = memoize(() =>
    createSelector(selectRootEvaluationScope(), (evaluationScope) =>
      evaluationScope.evaluateVariables(),
    ),
  );

  const select = {
    questionnaire: constant(toSelector(selectQuestionnaire)),
    validationErrors: constant(toSelector(selectValidationErrors)),
    formValues: selectFormValues,
    dynamicVariables,
    variable: (key) => createSelector(dynamicVariables(), createGetAtKey(key)),
    dynamicProperties: selectDynamicProperties,
    hasValidationErrors: memoize(() =>
      createSelector(
        toSelector(selectQuestionnaire),
        toSelector(selectValidationErrors),
        selectDynamicProperties(),
        (questionnaire, formErrors, properties) =>
          !!cleanEmptyValues(
            maskHiddenFormValues(questionnaire, formErrors, properties),
          ),
      ),
    ),
    formErrors: memoize(() =>
      reconcilingSelector(
        toSelector(selectQuestionnaire),
        selectFormValues(),
        toSelector(selectValidationErrors),
        selectDynamicProperties(),
        (questionnaire, formValues, formErrors, properties) =>
          getFormErrors(questionnaire, formValues, {
            properties,
            overwrite: formErrors,
            skipHidden: true,
          }),
      ),
    ),
    touched: memoize(() =>
      createSelector(
        toSelector(selectQuestionnaire),
        toSelector(selectRawTouched),
        selectDynamicProperties(),
        // TODO: Explain why exactly we need to mask here. This was causing problems
        //       because "touched" object does not have "value: []" for collections
        //       and as a result everything was masked inside collection.
        (questionnaire, touched, properties) =>
          maskHiddenFormValues(questionnaire, touched, properties),
      ),
    ),
    evaluationScope: (selectScopeKey) =>
      createSelector(
        selectRootEvaluationScope(),
        toSelector(selectScopeKey),
        (rootEvaluationScope, scopeKey) =>
          rootEvaluationScope.getScopeForScopeKey(scopeKey),
      ),
    dynamicQuestionnaire: (selectScopeKey) =>
      createSelector(
        toSelector(selectQuestionnaire),
        selectDynamicProperties(),
        toSelector(selectScopeKey),
        (questionnaire, properties, scopeKey) =>
          getDynamicQuestionnaire(questionnaire, properties, scopeKey),
      ),
    questionCursor: (
      selectQuestionId,
      selectHierarchy,
      { force = false } = {},
    ) => {
      const isValidScreen = (cursor) => {
        // NOTE: By convention we are only showing prologue for sections
        //       and epilogue for collections. In the future, we will probably
        //       want it to be configurable at questionnaire ui level.
        if (cursor.isEpilogue() && cursor.isSection()) {
          return false;
        }
        if (cursor.isPrologue() && cursor.isCollection()) {
          return false;
        }
        return true;
      };
      const defaultStepFilter = (cursor) =>
        isValidScreen(cursor) && cursor.isVisible();
      //------------------------------------------------------------------------------
      return createSelector(
        toSelector({
          questionnaire: selectQuestionnaire,
          properties: selectDynamicProperties(),
          formValues: selectFormValues(),
          questionId: selectQuestionId,
          hierarchy: selectHierarchy,
          sortedBy: selectSortedBy,
          flatSections: selectFlatSections,
        }),
        (params) => {
          if (force) {
            // NOTE: If "force" is used we seek to the given question even if it is not visible.
            return QuestionCursor.begin({
              ...params,
              stepFilter: isValidScreen,
            }).setFilters({
              stepFilter: defaultStepFilter,
            });
          }
          return QuestionCursor.begin({
            ...params,
            stepFilter: defaultStepFilter,
          });
        },
      );
    },
  };

  return select;
};
