import {
  ENGLISH_VALUE,
  getNavigatorLanguages,
  getPreferredLanguage,
  initI18next as originalInitI18next,
} from '../common/utils/i18next';
import { parse } from '../common/utils/querystring';
import resolveOnSelector from '../common/utilsClient/redux/resolveOnSelector';
import { setUserLanguage, getUserLanguage } from '../store/preferences';

export const supportedLanguages = process.env.REACT_APP_LANGUAGES
  ? process.env.REACT_APP_LANGUAGES.split(',')
  : [ENGLISH_VALUE];

const supportedNamespaces = ['common'];

let initialLanguages;
if (window.location.search) {
  const query = parse(window.location.search);
  if (query.i18n) {
    initialLanguages = query.i18n.split(',');
  }
}

// eslint-disable-next-line no-underscore-dangle
const isReady = (state) => state && state._persist && state._persist.rehydrated;

export const initI18next = (store) => {
  // see https://www.i18next.com/misc/creating-own-plugins#languagedetector
  const languageDetector = {
    type: 'languageDetector',
    async: true,
    init: () => {},
    detect: (callback) => {
      resolveOnSelector(store, isReady)
        .then(() => {
          const language = getUserLanguage(store.getState());
          if (language) {
            return [language];
          }
          return getNavigatorLanguages();
        })
        .then((languages) => {
          if (initialLanguages && initialLanguages.length > 0) {
            callback(
              getPreferredLanguage(
                languages,
                initialLanguages,
                initialLanguages[0],
              ),
            );
          } else {
            callback(languages[0]);
          }
        })
        .catch((err) => {
          console.error(err);
          callback(null);
        });
    },
    cacheUserLanguage: (language) => {
      store.dispatch(setUserLanguage(language));
    },
  };
  originalInitI18next({
    supportedLanguages,
    supportedNamespaces,
    languageDetector,
  });
};
