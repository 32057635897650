import { useState, useEffect } from 'react';

const useLoading = (promise) => {
  const [isLoading, setIsLoading] = useState(!!promise);
  useEffect(() => {
    let canceled = false;
    const resolve = () => {
      if (!canceled) {
        setIsLoading(false);
      }
    };
    if (promise) {
      promise.then(resolve).catch(resolve);
    }
    return () => {
      canceled = true;
    };
  });
  return isLoading;
};

export default useLoading;
