import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import {
  ACTION_SET,
  ACTION_INC,
  ACTION_DEL,
  ACTION_PUSH,
  ACTION_PULL,
  ACTION_PULL_WHERE,
} from './constants';
import {
  setAtKey,
  incAtKey,
  delAtKey,
  pushAtKey,
  pullAtKey,
  pullWhereAtKey,
  splitKey,
} from '../immutable';
import {
  set,
  inc,
  del,
  push,
  pull,
  pullWhere,
  scopeActionCreator,
} from './actions';
import createGetAtKey from '../createGetAtKey';
import shallowEqual from '../shallowEqual';

const scopeRe = /^@SCOPE\[.*/;
const compose = (...functions) =>
  functions.reduce(
    (x, y) =>
      (...args) =>
        x(y(...args)),
  );
const identity = (x) => x;
const registeredStores = {};
const listeners = [];

export const createMultiReducer = (options = {}) => {
  const sections = {};
  const reducers = Object.create(options.reducers || {});
  const initialState = options.initialState || {};

  const multiReducer = (state = initialState, action) => {
    if (scopeRe.test(action.type)) {
      const { section, reducer } = action.meta || {};
      const [k, tail] = splitKey(section);
      if (tail) {
        return setAtKey(
          state,
          k,
          (sections[k] || multiReducer.pureReducer)(state && state[k], {
            ...action,
            meta: {
              ...action.meta,
              section: tail,
            },
          }),
        );
      }
      // if k is empty, this function simply replaces the existing value
      return setAtKey(
        state,
        k,
        (reducers[reducer] || multiReducer.pureReducer)(
          k ? state && state[k] : state,
          action.payload,
        ),
      );
    }

    if (
      action.type === ACTION_SET ||
      action.type === ACTION_INC ||
      action.type === ACTION_DEL ||
      action.type === ACTION_PUSH ||
      action.type === ACTION_PULL ||
      action.type === ACTION_PULL_WHERE
    ) {
      const metaOptions = action.meta && action.meta.options;
      const key = action.meta && action.meta.key;
      const [k, tail] = splitKey(key);
      if (tail) {
        const stateAtKey = (sections[k] || multiReducer.pureReducer)(
          state && state[k],
          {
            ...action,
            meta: {
              ...action.meta,
              key: tail,
            },
          },
        );
        if (action.type === ACTION_DEL) {
          const isCascade = metaOptions && metaOptions.cascade;
          if (isCascade && isEmpty(stateAtKey)) {
            return delAtKey(state, k);
          }
        }
        return setAtKey(state, k, stateAtKey);
      }
      switch (action.type) {
        case ACTION_SET:
          return setAtKey(state, k, action.payload);
        case ACTION_INC:
          return incAtKey(state, k, action.payload);
        case ACTION_DEL:
          return delAtKey(state, k, {
            defaultValue: initialState,
            ...metaOptions,
          });
        case ACTION_PUSH:
          return pushAtKey(state, k, action.payload);
        case ACTION_PULL:
          return pullAtKey(state, k, action.payload, metaOptions);
        case ACTION_PULL_WHERE:
          return pullWhereAtKey(state, k, action.payload, metaOptions);
        default:
        // do nothing
      }
    }

    let nextState = state;

    if (!isEmpty(sections)) {
      const nextNextState = {
        ...nextState,
        ...mapValues(sections, (reducer, key) =>
          reducer(nextState && nextState[key], action),
        ),
      };
      if (!shallowEqual(nextState, nextNextState)) {
        nextState = nextNextState;
      }
    }

    if (options.default) {
      nextState = options.default(nextState, action);
    }

    return nextState;
  };

  const isPureReducer =
    options.sections === null && !options.useRegisteredStores;

  multiReducer.pureReducer = isPureReducer
    ? multiReducer
    : createMultiReducer({
        reducers,
        sections: null,
      });

  multiReducer.reducer = (id, reducer) => {
    reducers[id] = reducer;
  };

  let getState;
  const defaultGetState = (state) => {
    if (!getState) {
      getState = createGetAtKey(multiReducer.rootKey);
    }
    return getState(state);
  };

  multiReducer.getState = options.getState || defaultGetState;

  multiReducer.section = (key, reducer, sectionInitialState) => {
    if (options.sections === null) {
      throw new Error('Cannot create sections on pure reducers');
    }
    const [k, tail] = splitKey(key);
    if (typeof reducer === 'function') {
      if (!tail) {
        if (sections[k]) {
          throw new Error(`Cannot attach custom reducer at key ${key}`);
        }
        sections[k] = reducer;
      }
    }
    if (!sections[k]) {
      sections[k] = createMultiReducer({
        reducers,
        sections: isPlainObject(reducer) ? reducer : {},
        initialState: tail
          ? initialState[k]
          : sectionInitialState || initialState[k],
        getState: compose(createGetAtKey(k), multiReducer.getState),
      });
    }
    let subSection;
    if (tail) {
      if (typeof sections[k].section !== 'function') {
        throw new Error(`Cannot create store section at key ${key}`);
      }
      subSection = sections[k].section(tail, reducer, sectionInitialState);
    } else {
      subSection = {
        set,
        inc,
        del,
        push,
        pull,
        pullWhere,
        get: (l) =>
          compose(createGetAtKey(l), createGetAtKey(k), multiReducer.getState),
      };
    }
    const wrap = scopeActionCreator(k);
    const section = {
      set: wrap(subSection.set),
      inc: wrap(subSection.inc),
      del: wrap(subSection.del),
      push: wrap(subSection.push),
      pull: wrap(subSection.pull),
      pullWhere: wrap(subSection.pullWhere),
      get: (l) => compose(createGetAtKey(l), subSection.get()),
      section: (l, ...args) => multiReducer.section(`${key}.${l}`, ...args),
    };
    section.create = {
      set:
        (l, transform = identity, defaultValue) =>
        (value = defaultValue) =>
          section.set(l, transform(value)),
      inc: (l) => section.inc.bind(null, l),
      del: (l) => section.del.bind(null, l),
      push: (l) => section.push.bind(null, l),
      pull: (l) => section.pull.bind(null, l),
      pullWhere: (l) => section.pullWhere.bind(null, l),
    };
    const createGet = section.get;
    section.get = (l, transform = identity, defaultValue) => {
      const selector = createGet(l);
      return (state) => {
        const value = selector(state);
        return transform(value !== undefined ? value : defaultValue);
      };
    };
    return section;
  };

  if (!isEmpty(options.sections)) {
    forEach(options.sections, (v, k) => {
      multiReducer.section(k, v);
    });
  }

  if (options.useRegisteredStores) {
    forEach(registeredStores, (sectionOptions, k) => {
      multiReducer.section(
        k,
        sectionOptions.reducer,
        sectionOptions.initialState,
      );
    });
    listeners.push((path) => {
      multiReducer.section(
        path,
        registeredStores[path].reducer,
        registeredStores[path].initialState,
      );
      if (options.onNewReducer) {
        options.onNewReducer(multiReducer, path);
      }
    });
  }

  return multiReducer;
};

const multiReducer = createMultiReducer();
const { pureReducer } = multiReducer;
const registerStore = ({ path, initialState, reducer }) => {
  registeredStores[path] = {
    initialState,
    reducer,
  };
  forEach(listeners, (listener) => listener(path));
  return multiReducer.section(path, reducer, initialState);
};

export default multiReducer;
export { pureReducer, registerStore };
