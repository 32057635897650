import { createSelector } from 'reselect';
import toSelector from '../common/utils/toSelector';

const selectJson = (selectJsonString) =>
  createSelector(toSelector(selectJsonString), (jsonString) => {
    let json;
    if (typeof jsonString === 'string') {
      try {
        json = JSON.parse(jsonString);
      } catch (err) {
        // ...
      }
    }
    return json;
  });

export default selectJson;
