import sanitizeHtml from 'sanitize-html';

function htmlToPlainText(html) {
  // Strip HTML tags for text body. Adapted from:
  // http://stackoverflow.com/questions/295566/sanitize-rewrite-html-on-the-client-side/430240#430240

  const tagBody = '(?:[^"\'>]|"[^"]*"|\'[^\']*\')*';
  const tagOrComment = new RegExp(
    `${
      '<(?:' +
      // Comment body.
      '!--(?:(?:-*[^->])*--+|-?)' +
      // Special "raw text" elements whose content should be elided.
      '|script\\b'
    }${tagBody}>[\\s\\S]*?</script\\s*` +
      `|style\\b${tagBody}>[\\s\\S]*?</style\\s*` +
      // Regular name
      `|/?[a-z]${tagBody})>`,
    'gi',
  );

  let previousHtml;
  let tempHtml = html;

  // QUESTION: Can we be sure that this loop always stops?
  do {
    previousHtml = tempHtml;
    tempHtml = tempHtml.replace(tagOrComment, '');
  } while (tempHtml !== previousHtml);

  return tempHtml.replace(/</g, '&lt;');
}

function htmlToPlainText2(html) {
  return sanitizeHtml(html, {
    allowedTags: [], // empty to strip all tags away
  });
}

export default htmlToPlainText;
export { htmlToPlainText2 };
