import React from 'react';
import ReactDOM from 'react-dom';
import Notifications from '../../containers/Notifications';
import createContainer from './createContainer';

const containerElement = createContainer();
let notify;

ReactDOM.render(
  <Notifications
    setNotify={(notifyFn) => {
      notify = notifyFn;
    }}
  />,
  containerElement,
);

const info = ({ message, duration }) => {
  return notify({
    message,
    duration,
  });
};

const success = ({ message, duration }) => {
  return notify({
    type: 'success',
    message,
    duration,
  });
};

const warning = ({ message, duration }) => {
  return notify({
    type: 'warning',
    message,
    duration,
  });
};

const error = ({ message, duration }) => {
  return notify({
    type: 'error',
    message,
    duration,
  });
};

export default {
  info,
  success,
  warning,
  error,
};
