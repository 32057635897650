import get from 'lodash/get';
import Formula from '../Formula';
import { FORMULA_TYPE__VARIABLE_VALUE } from '../../../constants';
import { collapse } from '../../../utils/formValues';

class FormulaVariableValue extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.id) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  compile() {
    const compiled = {
      ...this,
      settings: {
        id: this.settings.id,
      },
    };
    if (this.settings.initial) {
      compiled.settings.initial = this.settings.initial;
    }
    if (this.settings.bindingKey) {
      compiled.settings.bindingKey = this.settings.bindingKey;
    }
    return compiled;
  }

  evaluate(scope) {
    const variable = scope.lookupVariable(
      this.settings.id,
      this.settings.initial,
    );
    if (variable) {
      const value = collapse(variable);
      if (this.settings.bindingKey) {
        return {
          value: get(value, this.settings.bindingKey),
        };
      }
      return {
        value,
      };
    }
    return {
      error: this.constructor.NoData,
    };
  }

  toMongoExpression() {
    return this.constructor.createMongoExpression(this.settings.id);
  }

  static createMongoExpression() {
    return {
      $literal: '[unknown]',
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'id':
          return mapQuestionId(value);
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__VARIABLE_VALUE] = FormulaVariableValue;

export default FormulaVariableValue;
