import map from 'lodash/map';
import BaseModel from '../BaseModel';
import {
  FORMULA_TYPE__ALWAYS_TRUE,
  FORMULA_TYPE__ALWAYS_FALSE,
  FORMULA_TYPE__ANSWER_IS_ONE_OF,
  FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF,
  FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF,
  FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF,
  FORMULA_TYPE__VARIABLE_IS_ONE_OF,
  FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF,
  FORMULA_TYPE__ANSWER_EXISTS,
  FORMULA_TYPE__ANSWER_DOES_NOT_EXIST,
  BEHAVIOR_ACTION_TYPE__UNKNOWN,
  BEHAVIOR_ACTION_TYPE__HIDE_QUESTION,
  BEHAVIOR_ACTION_TYPE__HIDE_QUESTION_CHUNK,
  BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_EXISTS,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_EXISTS,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_DOES_NOT_EXIST,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_DOES_NOT_EXIST,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ALWAYS,
  BEHAVIOR_CONDITION_TYPE__NEVER,
  BEHAVIOR_CONDITION_TYPE__CUSTOM,
  BEHAVIOR_ACTION_TYPE__SHOW_QUESTION,
  FORMULA_OPPOSITES,
} from '../../constants';
import Formula from '../Formula';
import FormulaUnary from '../Formula/types/FormulaUnary';
import FormulaBinary from '../Formula/types/FormulaBinary';
import FormulaLiteral from '../Formula/types/FormulaLiteral';
import FormulaAnswerExists from '../Formula/types/FormulaAnswerExists';
import FormulaAnswerIsOneOf from '../Formula/types/FormulaAnswerIsOneOf';
import FormulaVariableIsOneOf from '../Formula/types/FormulaVariableIsOneOf';
import Action from './Action';

const CONDITION_TO_FORMULA = {
  [BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_ONE_OF,
  [BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_NOT_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF,
  [BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_EXISTS]: FORMULA_TYPE__ANSWER_EXISTS,
  [BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_DOES_NOT_EXIST]:
    FORMULA_TYPE__ANSWER_DOES_NOT_EXIST,
  [BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF,
  [BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF,
  [BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_ONE_OF,
  [BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_NOT_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF,
  [BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_EXISTS]: FORMULA_TYPE__ANSWER_EXISTS,
  [BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_DOES_NOT_EXIST]:
    FORMULA_TYPE__ANSWER_DOES_NOT_EXIST,
  [BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF,
  [BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF,
  [BEHAVIOR_CONDITION_TYPE__ALWAYS]: FORMULA_TYPE__ALWAYS_TRUE,
  [BEHAVIOR_CONDITION_TYPE__NEVER]: FORMULA_TYPE__ALWAYS_FALSE,
};

class QuestionBehavior extends BaseModel {
  constructor(doc) {
    super(doc);
    this.thenActions = map(this.thenActions, (rawAction) =>
      this.constructor.createAction(rawAction),
    );
    this.elseActions = map(this.elseActions, (rawAction) =>
      this.constructor.createAction(rawAction),
    );
  }

  doActions(value, ...args) {
    let mutations = [];
    if (value === true) {
      this.thenActions.forEach((action) => {
        mutations = [...mutations, ...action.doSelf(...args)];
      });
    } else if (value === false) {
      this.elseActions.forEach((action) => {
        mutations = [...mutations, ...action.doSelf(...args)];
      });
    }
    return mutations;
  }

  undoActions(value, ...args) {
    let mutations = [];
    if (value === true) {
      this.thenActions.forEach((action) => {
        mutations = [...mutations, ...action.undoSelf(...args)];
      });
    } else if (value === false) {
      this.elseActions.forEach((action) => {
        mutations = [...mutations, ...action.undoSelf(...args)];
      });
    }
    return mutations;
  }

  createFormula() {
    if (this.formula) {
      return Formula.create(this.formula);
    }
    const condition = this.ifCondition;
    if (!condition) {
      return FormulaLiteral.withValue(false);
    }
    return this.constructor.createFormula(condition);
  }

  static createFormula(condition, tryOpposite = true) {
    switch (condition.formulaType) {
      case FORMULA_TYPE__ANSWER_EXISTS:
        return FormulaAnswerExists.withQuestionId(condition.questionId);
      case FORMULA_TYPE__ANSWER_DOES_NOT_EXIST:
        return FormulaUnary.not(
          FormulaAnswerExists.withQuestionId(condition.questionId),
        );
      case FORMULA_TYPE__ANSWER_IS_ONE_OF:
        return FormulaAnswerIsOneOf.withQuestionIdAndLiterals(
          condition.questionId,
          condition.literals,
        );
      case FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF:
        return FormulaBinary.and(
          FormulaAnswerExists.withQuestionId(condition.questionId),
          FormulaUnary.not(
            FormulaAnswerIsOneOf.withQuestionIdAndLiterals(
              condition.questionId,
              condition.literals,
            ),
          ),
        );
      case FORMULA_TYPE__ALWAYS_TRUE:
        return FormulaLiteral.withValue(true);
      case FORMULA_TYPE__ALWAYS_FALSE:
        return FormulaLiteral.withValue(false);
      case FORMULA_TYPE__VARIABLE_IS_ONE_OF:
        return FormulaVariableIsOneOf.withVariableIdAndLiterals(
          condition.variableId,
          condition.literals,
        );
      case FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF:
        return FormulaUnary.not(
          FormulaVariableIsOneOf.withVariableIdAndLiterals(
            condition.variableId,
            condition.literals,
          ),
        );
      default: {
        if (tryOpposite) {
          const oppositeType = FORMULA_OPPOSITES[condition.formulaType];
          if (oppositeType) {
            const oppositeFormula = this.createFormula(
              {
                ...condition,
                formulaType: oppositeType,
              },
              // pass false to prevent infinite loop
              false,
            );
            return FormulaUnary.not(oppositeFormula);
          }
        }
        // TODO: Instead we should generate formula which always
        //       results in error; false is not really correct because
        //       it can be negated, in which case it will result
        //       in condition being fulfilled.
        return FormulaLiteral.withValue(false);
      }
    }
  }

  static compileAll(rawBehaviors, questionsHierarchy) {
    return rawBehaviors.map(
      ({
        id,
        currentQuestionId = null,
        actionType,
        formula,
        conditionType,
        targetQuestionId = null,
        acceptedValues = [],
        questionIdToCheck = null,
        settings,
      }) => {
        let negateCondition;
        const action = {
          type: actionType,
          settings: {
            ...settings,
          },
        };
        switch (actionType) {
          case BEHAVIOR_ACTION_TYPE__SHOW_QUESTION:
            action.type = BEHAVIOR_ACTION_TYPE__HIDE_QUESTION;
            action.target = currentQuestionId;
            negateCondition = true;
            break;
          case BEHAVIOR_ACTION_TYPE__HIDE_QUESTION:
          case BEHAVIOR_ACTION_TYPE__HIDE_QUESTION_CHUNK:
            action.target = currentQuestionId;
            break;
          case BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION:
            action.target = currentQuestionId;
            action.settings.questionId = targetQuestionId;
            break;
          default:
          // do nothing ...
        }
        const compiled = {
          id,
          thenActions: [action],
        };
        const ifCondition = {};
        switch (conditionType) {
          case BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_ONE_OF:
          case BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_NOT_ONE_OF:
          case BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_ONE_OF:
          case BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF:
            ifCondition.questionId = currentQuestionId;
            ifCondition.literals = acceptedValues;
            ifCondition.formulaType = CONDITION_TO_FORMULA[conditionType];
            break;
          case BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_EXISTS:
          case BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_DOES_NOT_EXIST:
            ifCondition.questionId = currentQuestionId;
            ifCondition.formulaType = CONDITION_TO_FORMULA[conditionType];
            break;
          case BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_ONE_OF:
          case BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_NOT_ONE_OF:
          case BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_ONE_OF:
          case BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF:
            ifCondition.questionId = questionIdToCheck;
            ifCondition.literals = acceptedValues;
            ifCondition.formulaType = CONDITION_TO_FORMULA[conditionType];
            break;
          case BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_EXISTS:
          case BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_DOES_NOT_EXIST:
            ifCondition.questionId = questionIdToCheck;
            ifCondition.formulaType = CONDITION_TO_FORMULA[conditionType];
            break;
          default:
            ifCondition.formulaType =
              CONDITION_TO_FORMULA[conditionType] || FORMULA_TYPE__ALWAYS_FALSE;
        }
        let finalFormula;
        if (conditionType === BEHAVIOR_CONDITION_TYPE__CUSTOM) {
          finalFormula = formula && Formula.create(formula);
        } else {
          finalFormula = this.createFormula(ifCondition);
        }
        if (finalFormula && negateCondition) {
          // NOTE: If finalFormula results in #ERR, it's not a problem
          //       because NOT #ERR === TRUE by definition.
          finalFormula = FormulaUnary.not(finalFormula);
        }
        if (finalFormula) {
          compiled.formula = finalFormula.compile(questionsHierarchy);
        }
        return compiled;
      },
    );
  }

  static createAction(doc) {
    let constructor = this.actions[doc.type];
    if (!constructor) {
      constructor = this.actions[BEHAVIOR_ACTION_TYPE__UNKNOWN];
    }
    return new constructor(doc);
  }
}

QuestionBehavior.Action = Action;
QuestionBehavior.actions = {};

export default QuestionBehavior;
