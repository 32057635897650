import { useRef } from 'react';
import reconcile from './reconcile';

const useReconcile = (value) => {
  const ref = useRef(null);
  ref.current = reconcile(ref.current, value);
  return ref.current;
};

export default useReconcile;
