// based on:
// https://github.com/erikras/redux-form/blob/master/src/events/getValue.js

const isEvent = (candidate) =>
  !!(candidate && candidate.stopPropagation && candidate.preventDefault);

const getSelectedValues = (options) => {
  const result = [];
  if (options) {
    for (let index = 0; index < options.length; index += 1) {
      const option = options[index];
      if (option.selected) {
        result.push(option.value);
      }
    }
  }
  return result;
};

const getValue = (event, isReactNative) => {
  if (isEvent(event)) {
    if (
      !isReactNative &&
      event.nativeEvent &&
      event.nativeEvent.text !== undefined
    ) {
      return event.nativeEvent.text;
    }
    if (isReactNative && event.nativeEvent !== undefined) {
      return event.nativeEvent.text;
    }
    const detypedEvent = event;
    const {
      target: { type, value, checked, files },
      dataTransfer,
    } = detypedEvent;
    if (type === 'checkbox') {
      return !!checked;
    }
    if (type === 'file') {
      return files || (dataTransfer && dataTransfer.files);
    }
    if (type === 'select-multiple') {
      return getSelectedValues(event.target.options);
    }
    return value;
  }
  return event;
};

export default getValue;
