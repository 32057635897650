import isDate from 'lodash/isDate';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import shallowEqual from './shallowEqual';

const identity = {
  '*': (x) => x,
  key: () => {
    return identity;
  },
};

const stableMap = (arrayOrObject, transform) => {
  if (isArray(arrayOrObject)) {
    const newArray = map(arrayOrObject, transform);
    if (shallowEqual(arrayOrObject, newArray)) {
      return arrayOrObject;
    }
    return newArray;
  }
  if (isPlainObject(arrayOrObject)) {
    const newObject = mapValues(arrayOrObject, transform);
    if (shallowEqual(arrayOrObject, newObject)) {
      return arrayOrObject;
    }
    return newObject;
  }
  return arrayOrObject;
};

function makeCallable(f) {
  function callType(type, value) {
    if (typeof f[type] === 'function') {
      return f[type](value);
    }
    if (typeof f['*'] === 'function') {
      return f['*'](value);
    }
    return null;
  }
  function wrapped(value) {
    if (isArray(value)) {
      return callType('array', value) || map(value, wrapped);
    }
    if (isPlainObject(value)) {
      return callType('object', value) || mapValues(value, wrapped);
    }
    if (isDate(value)) {
      return callType('date', value);
    }
    if (typeof value === 'string') {
      return callType('string', value);
    }
    if (typeof value === 'number') {
      return callType('number', value);
    }
    if (typeof value === 'boolean') {
      return callType('boolean', value);
    }
    return null;
  }
  return wrapped;
}

function reconcileMap(a, fa, b, f, { level = -1, noResultReconcile } = {}) {
  const evaluate = (x) => {
    const fx = makeCallable(f)(x);
    if (noResultReconcile) {
      return fx;
    }
    return reconcileMap(fa, fa, fx, identity, {
      level: level > 0 ? level - 1 : level,
      noResultReconcile: true,
    });
  };
  if (level === 0) {
    return evaluate(b);
  }
  if (a === b) {
    return fa;
  }
  if (!isObject(b)) {
    return makeCallable(f)(b);
  }
  if (isDate(b)) {
    if (shallowEqual(a, b)) {
      return fa;
    }
    return makeCallable(f)(b);
  }
  if (isArray(b) || isPlainObject(b)) {
    if (f.key) {
      const fb = stableMap(b, (v, k) =>
        reconcileMap(a && a[k], fa && fa[k], v, f.key(k), {
          level: level > 0 ? level - 1 : level,
          noResultReconcile,
        }),
      );
      if (shallowEqual(fa, fb)) {
        return fa;
      }
      return fb;
    }
    const b1 = reconcileMap(a, a, b, identity, {
      level,
      noResultReconcile: true,
    });
    if (b1 === a) {
      return fa;
    }
    return evaluate(b1);
  }
  return evaluate(b);
}

export { reconcileMap };

const reconcile = (a, b, options) => {
  return reconcileMap(a, a, b, identity, {
    ...options,
    noResultReconcile: true,
  });
};

export default reconcile;
