import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

const isNotNil = (x) => !isNil(x);

export const createCleanEmptyValues = (options = {}) => {
  const { keepEmptyArrays, keepEmptyObjects } = options;
  function cleanEmptyValues(value) {
    if (isArray(value)) {
      const newArray = filter(map(value, cleanEmptyValues), isNotNil);
      if (!keepEmptyArrays && isEmpty(newArray)) {
        return null;
      }
      return newArray;
    }
    if (isPlainObject(value)) {
      const newObject = omitBy(mapValues(value, cleanEmptyValues), isNil);
      if (!keepEmptyObjects && isEmpty(newObject)) {
        return null;
      }
      return newObject;
    }
    return value;
  }
  return cleanEmptyValues;
};

export default createCleanEmptyValues({});
