import { useMemo } from 'react';
import SelectorsHub from './SelectorsHub';

const useSelectorsHub = ({
  name,
  questionnaire,
  properties,
  variables,
  sortedBy,
  sessionId,
  answersSheetId,
  debounceEdit,
  disabled,
}) => {
  return useMemo(
    () =>
      new SelectorsHub({
        name,
        questionnaire,
        properties,
        variables,
        sortedBy,
        sessionId,
        answersSheetId,
        debounceEdit,
        disabled,
      }),
    [
      name,
      questionnaire,
      properties,
      variables,
      sortedBy,
      sessionId,
      answersSheetId,
      debounceEdit,
      disabled,
    ],
  );
};

export default useSelectorsHub;
